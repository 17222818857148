import { get, values } from 'lodash';
import React, { useState } from 'react';

import DefaultContainer from './DefaultContainer';
import LegacyToolsNav from '../components/LegacyToolsNav';
import { ThemeTileContainer } from '../components/theme/ThemeTile';
import { IconButton } from '@commonsku/styles';
import ThemeSidepanel from '../components/theme/ThemeSidepanel';
import { useSelector } from 'react-redux';

const ManageThemesApp = () => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  let themes = useSelector((state) => values(get(state, 'entities.themes', [])));

  return <DefaultContainer>
    <LegacyToolsNav />
    {selectedTheme !== null && <ThemeSidepanel
      key={get(selectedTheme, 'theme_id')}
      theme={selectedTheme}
      onClose={() => setSelectedTheme(null)}
    />}
    <div className='row full-width' style={{ display: 'flex', justifyContent: 'end', marginBottom: 19 }}>
      <IconButton
        iconPosition='left' preset="add" variant="primary" size="huge" mr={16}
        onClick={() => setSelectedTheme({})}
      >Add Theme</IconButton>
    </div>
    <ThemeTileContainer themes={themes} onClick={setSelectedTheme} />
  </DefaultContainer>;
};

export default ManageThemesApp;
