import { get, first, isEmpty, pick, round, subtract } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, SidePanel, IconButton, LabeledInput, Dropzoned, colors, TrashIcon, ToggleSwitchStyled } from '@commonsku/styles';

import { getImageSrc } from '../../utils';
import styled from 'styled-components';

import { createAddTheme, createUpdateTheme, createDeleteTheme } from '../../actions/theme';
import { createUploadFile } from '../../actions/file';

const ImagePreview = styled.div`
  && {
    margin-top: 22px;
    background-size: cover;
    height: 140px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    background-size: cover;
    background-position: center center;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(8, 57, 82, ${props => props.opacity});
    }
  }
`;

const TextPreview = styled.div`
  && {
    position: relative; 
    font-family: "skufont-demibold",sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
`;

const Slider = styled.input.attrs(props => ({
  type: "range"
}))`
  && {
      -webkit-appearance: none;
      height: 7px;
      background: #808080;
      border-radius: 5px;
      background-image: linear-gradient(${colors.teal['60']}, ${colors.teal['60']});
      background-repeat: no-repeat;
    
  ::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: ${colors.teal['60']};
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }
    
  ::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
    
    
  }
`;

const MAX_OPACITY = 1;

const ThemeSidepanel = ({
  theme: initialValue,
  onUpdateTheme,
  onDeleteTheme,
  uploadFile,
  onClose,
}) => {
  const [selectedFile, _setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(getImageSrc(initialValue, 'large', { defaultImage: '' }));
  const [theme, setTheme] = useState({
    theme_name: '',
    opacity: MAX_OPACITY,
    is_featured: false,
    ...initialValue,
  });
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, []);
  const setSelectedFile = (file) => {
    _setSelectedFile(file);
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    if (file) {
      setPreview(URL.createObjectURL(file));
    }
  };
  const isEdit = !isEmpty(theme.theme_id);

  const handleClickSave = async () => {
    let file_id = null;
    if (selectedFile) {
      file_id = get(await uploadFile(theme.theme_id, 'THEME', selectedFile), 'payload.id');
    }
    const data = file_id ? { ...theme, file_id } : theme;
    const newTheme = get(await onUpdateTheme(data), 'payload.data');
    if (newTheme) {
      setTheme(newTheme);
    }
  };
  const opacity = round(subtract(1 - theme.opacity), 2);

  return <SidePanel
    style={{ padding: '2rem', width: '600px', paddingBottom: 15 }}
    padded
    visible={!isEmpty(theme)}
    title={`${isEdit ? 'Edit' : 'Create'} Theme`}
    controls={<IconButton variant="text" preset="close" mr={16} onClick={(e) => {
      onClose();
    }} />}
  >
    <LabeledInput
      labelOnTop label='Name' name="basic-input" placeholder="Enter theme name" value={theme.theme_name}
      onChange={e => setTheme({ ...theme, theme_name: e.target.value })}
    />
    <div style={{ marginBottom: 12 }}>
      <label style={{ fontFamily: '"skufont-demibold",sans-serif', fontWeight: 500, fontSize: 16, lineHeight: '24px' }}>Include in featured themes</label>
      <ToggleSwitchStyled
        size="medium"
        selected={+theme.is_featured === 1}
        onClick={() => setTheme({ ...theme, is_featured: +theme.is_featured !== 1 ? 1 : 0 })}
        style={{ width: 60 }}
      />
    </div>
    {
      isEdit && <>
        <Dropzoned
          accept="image/*" placeholder={'Drag and drop image here or Browse Files  '}
          onDrop={(acceptedFiles, rejectedFiles, event) => {
            setSelectedFile(first(acceptedFiles));
          }}
        />
        {preview &&
          <>
            <ImagePreview style={{ backgroundImage: `url(${preview})` }} opacity={opacity}>
              <TextPreview>{theme.theme_name}</TextPreview>
            </ImagePreview>
            <label style={{ marginTop: 22, fontFamily: '"skufont-demibold",sans-serif', fontWeight: 500, fontSize: 16, lineHeight: '24px' }}>Background Opacity</label>
            <Slider
              className='full-width' style={{ marginTop: 22, backgroundSize: `${theme.opacity * 100}% 100%` }}
              type="range" min="0" max="10" value={theme.opacity * 10}
              onChange={(e) => setTheme({ ...theme, opacity: e.target.value / 10 })}
            />
          </>
        }
      </>
    }

    <Button className='full-width' style={{ marginBottom: 22, marginTop: 22 }} onClick={() => handleClickSave()} >
      {isEdit ? 'Save' : 'Create'}
    </Button>
    {isEdit && <div style={{ textAlign: 'center' }}>
      <IconButton
        variant='custom'
        Icon={TrashIcon}
        variantBorderColor='transparent'
        variantColor={colors.errors.main}
        variantBg='transparent'
        variantHoverBorderColor={colors.errors['10']}
        variantHoverColor={colors.errors.main}
        variantHoverBg={colors.errors['10']}
        size="large"
        onClick={() => onDeleteTheme(theme.theme_id)}
      >Delete Theme</IconButton>
    </div>}
  </SidePanel>;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpdateTheme: (theme) => {
      theme = pick(theme, ['theme_id', 'theme_name', 'file_id', 'opacity', 'is_featured']);
      return dispatch(isEmpty(theme.theme_id) ? createAddTheme(theme) : createUpdateTheme(theme));
    },
    onDeleteTheme: (theme_id) => {
      dispatch(createDeleteTheme(theme_id));
      ownProps.onClose();
    },
    uploadFile: (parent_id, parent_type, file_blob, folder_id = null) => {
      try {
        return dispatch(createUploadFile(parent_id, parent_type, file_blob, folder_id));
      } catch (error) {
        console.log('UPLOAD ERROR: ', error);
      }
    },

  };
};
const ConnectedThemeSidepanel = connect(mapStateToProps, mapDispatchToProps)(ThemeSidepanel);
export default ConnectedThemeSidepanel;
